import React from 'react'
import Waves from "../../assets/images/waves.inline.svg";
import HeroImage from "../../assets/images/pallete.png";


class Hero extends React.Component {

    render() {
        const heroTitle = this.props.title
        const heroSubtitle = this.props.subtitle

        return (
            <div>
                <div className="hero">
                    <div className="hero__header">
                        <div className="hero__content">
                            <div className="hero__name">{heroTitle}</div>
                            <h1>{heroSubtitle}</h1>
                            <div className="hero__decoration">
                                <img src={HeroImage} alt="Vižážistka s klientkou v salónu" width="500" height="411" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <Waves />
                    </div>
                </div>
            </div>
        )
    }
}

export default Hero
