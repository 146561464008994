import React from 'react'
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import Video from '../Video'

class VideosWithDescription extends React.Component {
    render() {
        const videosWithDescriptionItemData = this.props.data
        const videosWithDescriptionElements = videosWithDescriptionItemData.elements
        const title = videosWithDescriptionElements.title.value
        const description = videosWithDescriptionElements.description.value

        const videoItems = []
        const videos = videosWithDescriptionElements.videos.value
        videos.forEach(video => {
            videoItems.push(<Video data={video} key={video.id} />)
        })

        return (
            <div className="videos-with-description">
                <div className="row row-content">
                    <div className="col-lg-6 videos-with-description__videos">
                        {videoItems}
                    </div>
                    <div className="col-lg-6 videos-with-description__description">
                        <div className="section-heading">
                            <h3>{title}</h3>
                        </div>
                        <RichTextElement
                            value={description} />
                    </div>
                </div>
            </div>
        )
    }
}

export default VideosWithDescription

