import React from 'react'
import GatsbyImage from 'gatsby-image'
import { format } from 'react-string-format'

class Video extends React.Component {
    render() {
        const videoData = this.props.data
        const videoElements = videoData.elements
        const title = videoElements.title.value
        const url = videoElements.url.value
        const teaserImage = videoElements.teaser_image

        return (
            <div className="video">
                <a href={url} target="_blank" data-tracking-label={format('Video_{0}', title)} rel="noopener">
                    <figure className="figure figure--video">
                        {teaserImage.value[0] ? (
                            <GatsbyImage fluid={teaserImage.value[0].fluid} alt={title} />
                        ) : ""}
                    </figure>
                </a>
            </div>
        )
    }
}

export default Video

